import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Do = makeShortcode("Do");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "folders-drives-and-paths"
    }}>{`Folders, Drives, and Paths`}</h1>
    <p>{`Write names of folders, drives, and paths in title case.`}</p>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Find the report in the 2018 Reports folder, within the Reports by Year folder.`}</li>
        <li parentName="ul">{`Workday stores your report in the My Docs tab of your Drive navigation slider.`}</li>
      </ul>
    </Do>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      